// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../public/fonts/GrandSlang-Roman.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../public/fonts/GrandSlang-Roman.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n/* Disable horizontal scroll*/\nhtml, body {\n    max-width: 100%;\n    /* overflow-x: hidden; */\n    /* overflow-y: hidden; */\n    font-family:'Open Sans', sans-serif;\n    background-color: #000\n}\n@font-face {\n  font-family: \"GrandSlang-Roman\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n}\n/* .app {\n  width:100vw;\n}  */\n\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA,6BAA6B;AAC7B;IACI,eAAe;IACf,wBAAwB;IACxB,wBAAwB;IACxB,mCAAmC;IACnC;AACJ;AAEA;EACE,+BAA+B;EAC/B;6DACgE;AAClE;AACA;;IAEI","sourcesContent":["\n/* Disable horizontal scroll*/\nhtml, body {\n    max-width: 100%;\n    /* overflow-x: hidden; */\n    /* overflow-y: hidden; */\n    font-family:'Open Sans', sans-serif;\n    background-color: #000\n}\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');\n@font-face {\n  font-family: \"GrandSlang-Roman\";\n  src: url(\"../public/fonts/GrandSlang-Roman.woff2\") format(\"woff2\"),\n       url(\"../public/fonts/GrandSlang-Roman.woff\") format(\"woff\");\n}\n/* .app {\n  width:100vw;\n}  */\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
